import { createStore } from 'vuex';

export default createStore({
  state: {
    sessionID: 0,
    conDoctor: '',
    conService: '',
    conDate: '',
    conTime: '',
  },
  getters: {
  },
  mutations: {
    addSession(state, session) {
      state.sessionID = session;
    },
    addDoctor(state, doctor) {
      state.conDoctor = doctor;
    },
    addService(state, service) {
      state.conService = service;
    },
    addDate(state, appDate) {
      state.conDate = appDate;
    },
    addTime(state, appTime) {
      state.conTime = appTime;
    },
  },
  actions: {
  },
  modules: {
  },
});
