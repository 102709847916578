<template>
  <div>
    <LoginPage />
  </div>
</template>

<script>
import LoginPage from '@/components/LoginPage.vue';

export default {
  name: 'HomeView',
  components: { LoginPage },
};
</script>
