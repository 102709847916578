<template>
  <div>
    You have scheduled an appointment with {{ Doctor }} for {{ Service }}
    on {{ ApptDate }} at {{ ApptTime }}. Thank You.
  </div>
</template>

<script>
import store from '../store';

export default {
  data() {
    return {
      Doctor: store.state.conDoctor,
      Service: store.state.conService,
      ApptDate: store.state.conDate,
      ApptTime: store.state.conTime,
    };
  },
};

</script>
