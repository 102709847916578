<template>
  <div class="welcome-container">
    <div class="all-container">
      <div class="intro-container">
      <!-- pulls information from composition API.
            In this instance replaces PracticeName with value imported. -->
      <div class="logo">
        <img :src="PracticeLogo" alt="Logo"/>
      </div>
      <div class="practice-heading">
        {{ PracticeName }}.
      </div>
      <div class="instructions">
        Please login with your phone number and pin to get started.
      </div>
    </div>
    <div class="login-container">
      <!-- submit.prevent stops default action of a form
            and replaces it with the sendForm method which
            is found in the script section of the code. -->
      <form @submit.prevent="sendForm" v-if="!submitted">
        <span>
          <!-- Text Box for user to input phone number and saved in
                composition API under the variable PhoneNumber. -->
          <label for="Phone">
            <input type="text"
              placeholder="Phone Number"
              id="Phone" required
              v-model="PhoneNumber"
            >
          </label>
        </span>
        <span>
          <!-- Text Box for user to input phone number and saved in
                composition API under the variable PIN. -->
          <label for="PIN">
            <input type="password"
              placeholder="PIN"
              id="PIN" required
              v-model="PIN"
            >
          </label>
        </span>
        <span>
          <!-- Custom button with word submit. -->
          <button>Log In</button>
        </span>

      </form>
    </div>
    </div>
  </div>
</template>

<script>
// Imports the appointment query class from js file.
import AppointmentQuery from '../js/query';
// Imports the vuex store from the store folder so that we can save needed variables globally.
import store from '../store/index';

export default {
  data() {
    return {
      // Will be imported value from URL once web address is configured.
      ClientID: 'uAZITZIuqB24eodQXdnlk1nHEk=',
      PhoneNumber: '',
      PIN: '',
      PracticeName: '',
      PracticeLogo: '',
    };
  },
  methods: {
    /* async is used to run this method asynchronously so that the method can wait
    for a reply from the fetch (await used before AppointmentQuery) the promise will
    be completed before the program steps past the fetch. */
    async sendForm() {
      const res = await AppointmentQuery.getValidation(this.ClientID, this.PhoneNumber, this.PIN);
      // checking to make sure that the login information is valid on the back-end.
      if (res.IsValid) {
        // Message tracking on the console for troubleshooting purposes.
        console.log('You have been authenticated');
        // Saves the session ID into the store so that is could be used globally.
        store.commit('addSession', res.Session);
        // Pushes the webpage to the authenticated section of the web app.
        this.$router.push('/authorized');
      } else {
        // Message tracking should authentication fail.
        console.log('The credentials failed authorization');
        // Resets values of the credentials on the web page.
        this.PhoneNumber = '';
        this.PIN = '';
        // program pop up alert to warn credentials failed
        this.$router.push('/home');
      }
    },
    async welcomeScreen() {
      const results = await AppointmentQuery.welcomeScreen();
      this.PracticeName = results.Offices[0].Name;
      this.PracticeLogo = results.Offices[0].LogoPath;
      console.log(results);
    },
  },
  async mounted() {
    await this.welcomeScreen();
  },
};
</script>

<style lang="scss" scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding: 50px;
  background: url('../assets/Patterns/y-so-serious-white.png') repeat fixed;
}

.all-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  contain: strict;
  width: 60%;
  height: 80%;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 4px 5px 3px 3px rgb(0 0 0 / 30%);
}
.intro-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding-bottom: 15px;
}
.logo {
  width: 60px;
  contain: strict;
}
.practice-heading{
  font-size: 2.5vw;
  font-weight: bolder;
  color: #000;
}
.instructions {
  font-size: 1.3vw;
  color: #000;
  padding: 30px;
}
.login-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 600px;
}

span {
  display: block;
  box-sizing: border-box;
  padding: 5px;
}

input {
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid #fff;
  box-shadow: inset 0px 1px 1px rgba(0,0,0, 0.33);
}

button,
router-button {
  transition: 500ms ease all;
  cursor: pointer;
  width: 150px;
  height: 50px;
  border-radius: 15px;
  margin-top: 24px;
  padding: 12px 24px;
  background-color: #23d45b;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
</style>
