<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="appt-container">
    <div class="appt-header">
      Schedule an appointment
    </div>
  <div class="appt-info-container">
    <div class="instructions">
      Make a selection for each option that pops up, when all selections are made, click the
      "Submit" button that will appear to make your appointment.
    </div>
    <div class="selection">
      Doctor
    </div>
      <select v-model="selectedDoctor" class="select-style">
        <option disabled value="Choose a doctor">Choose a doctor</option>
        <option v-for="(doc_id) in PID" :key="doc_id">
        {{ doc_id.doctor_name }}
        </option>
      </select>
    <div class="selection" v-show="showService">
      Service:
      <select v-model="selectedService" class="select-style">
        <option disabled value="Choose a service">Choose a service</option>
        <option v-for="(service_id) in SID" :key="service_id">
          {{ service_id.service_name }}
        </option>
      </select>
    </div>
    <div class="selection" v-show="showDate">
      Date:
      <select v-model="selectedDate" class="select-style">
        <option disabled value="Choose a Date">Choose a date</option>
        <option v-for="(date) in UniqueDates" :key="date">
          {{ date.date }}
        </option>
      </select>
    </div>
    <div class="selection" v-show="showTime">
      Time:
      <select v-model="selectedTime" class="select-style">
        <option disabled value="Choose a Time">Choose a time</option>
        <option v-for="(time) in UniqueTime" :key="time">
          {{ time.time }}
        </option>
      </select>
    </div>
    <button @click="submit" v-show="showSubmit">SUBMIT</button>
  </div>
  </div>
</template>

<script>
import AppointmentQuery from '../js/query';
import store from '../store';

export default {
  data() {
    return {
      sessionIDImport: '',
      selectedDoctor: '',
      selectedService: '',
      selectedDate: '',
      selectedTime: '',
      clientID: '',
      doctorID: '',
      serviceID: '',
      res: [],
      countA: 0,
      countB: 0,
      countC: 0,
      countD: 0,
      countE: 0,
      countF: 0,
      countG: 0,
      PID: [],
      SID: [],
      Dates: [],
      UniqueDates: [],
      UniqueTime: [],
      showService: false,
      showDate: false,
      showTime: false,
      showSubmit: false,
      WeekDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      Month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'],
    };
  },
  methods: {
    async getAppointments() {
      this.res = await AppointmentQuery.getAppointmentData('EF235E70-1C16-47EC-B7B9-6C28F650D090');
      for (let x = 0; x < this.res.Data.length; x += 1) {
        if (!this.PID[this.countA]) {
          this.PID[this.countB] = {
            doc_id: this.res.Data[x].provider_id,
            doctor_name: this.res.Data[x].provider_name,
          };
          this.countB += 1;
          this.countA = this.countB - 1;
        } else if (this.PID[this.countA] !== null
          && this.PID[this.countA].doctor_name !== this.res.Data[x].provider_name) {
          this.PID[this.countB] = {
            doc_id: this.res.Data[x].provider_id,
            doctor_name: this.res.Data[x].provider_name,
          };
        }
      }
    },
    async submit() {
      const sessionCheck = store.state.sessionID;
      store.commit('addDoctor', this.selectedDoctor);
      store.commit('addService', this.selectedService);
      store.commit('addDate', this.selectedDate);
      store.commit('addTime', this.selectedTime);
      // eslint-disable-next-line max-len
      const test = await AppointmentQuery.sendInformation(sessionCheck, this.doctorID, this.serviceID, this.selectedDate, this.selectedTime);
      switch (test.StatusCode) {
        case 201: {
          console.log('Appointment created and submitted.');
          this.$router.push('/confirmation');
          break;
        }
        case 400: {
          this.$router.push('/');
          console.log('Missing information in form submission');
          break;
        }
        case 404: {
          this.$router.push('/');
          console.log('Invalid credentials were submitted with appointment');
          break;
        }
        case 406: {
          this.$router.push('/');
          console.log('This session has timed out, or the appointment is no longer available.');
          break;
        }
        default: {
          console.log('This scenario is not complete');
        }
      }
    },
  },
  async mounted() {
    await this.getAppointments();
  },
  watch: {
    selectedTime(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showSubmit = true;
      }
    },
    selectedDoctor(newVal, oldVal) {
      if (newVal !== oldVal) {
        for (let w = 0; w < this.PID.length; w += 1) {
          if (this.PID[w].doctor_name === newVal) {
            this.doctorID = w + 1;
          }
          this.showService = true;
        }
        for (let y = 0; y < this.res.Data.length; y += 1) {
          if (!this.SID[this.countC] && this.res.Data[y].provider_id === this.doctorID) {
            this.SID[this.countD] = {
              service_id: this.res.Data[y].service_type_id,
              service_name: this.res.Data[y].service_description,
            };
            this.countD += 1;
            this.countC = this.countD - 1;
          } else if (this.SID[this.countC] !== null
            && this.res.Data[y].provider_id === this.doctorID) {
            this.SID[this.countD] = {
              service_id: this.res.Data[y].service_type_id,
              service_name: this.res.Data[y].service_description,
            };
          }
        }
      }
    },
    async selectedService(newVal, oldVal) {
      if (newVal !== oldVal) {
        for (let v = 0; v < this.SID.length; v += 1) {
          if (this.SID[v].service_name === newVal) {
            this.serviceID = this.SID[v].service_id;
          }
        }
        for (let a = 0; a < this.res.Data.length; a += 1) {
          if (this.res.Data[a].provider_id === this.doctorID
            && this.res.Data[a].service_type_id === this.serviceID) {
            const dt = new Date(this.res.Data[a].slot_dt);
            const dtm = dt.getMonth();
            const dtd = dt.getDay();
            let hours = dt.getHours();
            let minutes = dt.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours %= 12;
            hours = hours || 12;
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            const dtt = `${hours}:${minutes} ${ampm}`;
            this.Dates[this.countE] = {
              date: this.res.Data[a].slot_dt.split('T')[0],
              time: dtt,
              month: this.Month[dtm],
              day: this.WeekDay[dtd],
            };
            this.countE += 1;
          }
        }
      }
      for (let g = 0; g < this.Dates.length; g += 1) {
        if (g < 1) {
          this.UniqueDates[g] = {
            date: this.Dates[g].date,
          };
        } else if (this.UniqueDates[this.countF].date !== this.Dates[g].date) {
          this.countF += 1;
          this.UniqueDates[this.countF] = {
            date: this.Dates[g].date,
          };
        }
      }
      this.showDate = true;
    },
    async selectedDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        for (let p = 0; p < this.Dates.length; p += 1) {
          if (this.selectedDate === this.Dates[p].date) {
            this.UniqueTime[this.countG] = {
              time: this.Dates[p].time,
            };
            this.countG += 1;
          }
        }
        this.showTime = true;
      }
    },
  },
};

</script>

<style lang="scss" scoped>

.appt-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding: 50px;
  background: url('../assets/Patterns/email-pattern.png') repeat fixed;
}
.appt-header {
  position: fixed;
  top: 0;
  left: 0 !important;
  padding-left: 20%;
  padding-top: 3%;
  font-size: 1.8vw;
  font-weight: bolder;
  color: #38618C;
}
.appt-info-container {
  width: 65%;
  height: 80vh;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  display: block;
  align-items: start;
  contain: strict;
  background-color: #35A7FF;
  border-radius: 8px;
  padding-top: 20px;
  box-shadow: 4px 5px 3px 3px rgb(0 0 0 / 20%);
}
.instructions {
  width: 80%;
}
.selection {
  font-size: 1.5vw;
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 50px;
  color: #38618C;
}

.select-style {
  display: block;
  width: 80%;
  height: 40px;
  border-radius: 8px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 5px;
}

li {
  color: black;
  list-style-type: none;
  padding: 20px;
}

button {
  width: 100px;
  height: 50px;
  background-color: lightgray;
  border-color: black;
  border-width: 2px;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

</style>
