import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AuthorizedView from '../components/AppointmentPage.vue';
import ConfirmationView from '../components/ConfirmationPage.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: HomeView },
    { path: '/home', component: HomeView },
    { path: '/login', component: HomeView },
    { path: '/authorized', component: AuthorizedView },
    { path: '/confirmation', component: ConfirmationView },
  ],
});

export default router;
