import store from '../store';
// The query endpoint defined outside the class to make it easy to change if need be.
const API = {
  baseWelcome: 'https://softworxsolutions.com/swapi/api/onlineschedule/welcomestart?',
  baseValidate: 'https://softworxsolutions.com/swapi/api/onlineschedule/validate',
  baseInfo: 'https://softworxsolutions.com/swapi/api/onlineschedule/information?',
  submitappt: 'https://softworxsolutions.com/swapi/api/onlineschedule/createappointment',
};

// Class that will hold all queries to the API
class APIQuery {
  static async welcomeScreen() {
    return new Promise((resolve) => {
      const base = API.baseWelcome;
      const client = 'ClientID=uAZITZIuqB24eodQXdnlk1nHEk=';
      const url = (`${base + client}`);
      fetch(url, {
        method: 'GET',
      })
        .then((result) => result.json())
        .then((json) => resolve(json))
        .catch((err) => console.log(err));
    });
  }

  // Takes in three values, the client ID, the username, and the password
  static async getValidation(clientID, username, password) {
    // Sets up an async call to the API so that it waits on an answer before moving forward
    return new Promise((resolve) => {
      // First part of the endpoint
      const base = API.baseValidate;
      // Puts all the parts together so that we can receive a reply from the API
      const url = (`${base}`);
      // Using a native fetch to query the API and setting parameters
      console.log('Sending Request');
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          ClientId: clientID,
          Phone: username,
          Pin: password,
        }),
      })
        .then((result) => result.json()) // Getting the result back
        .then((json) => resolve(json)) // putting it in JSON form
        .catch((err) => console.log(err)); // dealing with any errors
    });
  }

  static async getAppointmentData(clientID) {
    console.log('Sending Information: ', clientID);
    return new Promise((resolve) => {
      const base = API.baseInfo;
      const client = `UserSession=${store.state.sessionID}`;
      console.log('client: ', client);
      const url = (`${base + client}`);
      console.log('Sending Request');
      fetch(url, {
        method: 'GET',
      })
        .then((result) => result.json())
        .then((json) => resolve(json))
        .catch((err) => console.log(err));
    });
  }

  static async sendInformation(sessionID, doctorID, serviceID, date, time) {
    console.log('Submitting info');
    return new Promise((resolve) => {
      const base = API.submitappt;
      const datetime = `${date} ${time}`;
      console.log('in Submit - Session ID', sessionID);
      const data = {
        UserSession: sessionID.trim(),
        ProviderId: doctorID,
        ServiceTypeId: serviceID,
        StartDate: datetime,
      };
      console.log('Data: ', data);
      fetch(base, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(
          data,
        ),
      })
        .then((result) => result.json())
        .then((json) => resolve(json))
        .catch((err) => console.log(err));
    });
  }
}

// Exporting the class so that it can be used throughout the web app
export default APIQuery;
